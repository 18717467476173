@import "~ed-grid/ed-grid";
@import "~edteam-style-guides/edteam-style-guides";

/* ----- ESTILOS BASE ----- */

:root {
  /*  Variables del proyecto  */

  /*  Anchura máxima del contenido en desktop */
  --max-width : 1200px;

  /*  Colores  */
  --primary-color     : #1f2649;
  --primary-color-alt : #035742;
  --title-color       : #12261e;
  --text-color        : #677370;
  --body-bg-color     : #fafffd;
  --white-color       : #ffffff;
  --color             : var(--primary-color);
  --color-alt         : var(--primary-color-alt);

  /*  Fuentes  */
  --body-font  : 'Open Sans', sans-serif;
  --title-font : 'Proxima Nova Rg', sans-serif;

  /*  Tamaños  */
  --header-height     : 3rem;
  --section-spacing   : 4rem;
  --block-spacing     : 2rem;
  --body-line-height  : 1.6;
  --title-line-height : 1.3;

  /* Tamaños de fuentes */
  --h1-font-size      : 2rem;
  --h2-font-size      : 1.5rem;
  --h3-font-size      : 1.25rem;
  --h4-font-size      : 1rem;
  --big-font-size     : 1rem;
  --normal-font-size  : 0.875rem;
  --small-font-size   : 0.75rem;
  --smaller-font-size : 0.6875rem;

  /* Capas */
  --z-back       : -10;
  --z-normal     : 1;
  --z-front      : 10;
  --z-tooltip    : 20;
  --z-overlay    : 30;
  --z-modal      : 40;
  --z-fixed      : 50;
  --z-off-canvas : 100;

  /* Otros */
  --border-radius : 0.5rem;
  --box-shadow    : 0 2px 2px rgba(36, 66, 59, 0.1);
}

@media screen and (min-width: 1024px) {

  /*  Variables de tamaño en desktop  */
  :root {
    --header-height         : 4rem;
    --section-spacing       : 8rem;
    --block-spacing         : 4rem;
    --h1-font-size          : 3rem;
    --h2-font-size          : 2rem;
    --h3-font-size          : 1.5rem;
    --h4-font-size          : 1.25rem;
    --big-font-size         : 1.25rem;
    --normal-font-size      : 1rem;
    --small-font-size       : 0.875rem;
    --smaller-font-size     : 0.75rem;
  }

}

body {
  display          : flex;
  flex-direction   : column;
  min-height       : calc(100vh - var(--header-height));
  color            : var(--text-color);
  padding-top      : var(--header-height);
  font-family      : var(--body-font);
  font-size        : var(--normal-font-size);
  line-height      : var(--body-line-height);
  background-color : var(--body-bg-color);
}

a {
  color : var(--primary-color);
}

p {
  margin-top : 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top  : 0;
  color       : var(--title-color);
  font-family : var(--title-font);
  line-height : var(--title-line-height);
  font-weight : 800;
}

h1 {
  font-size:  var(--h1-font-size);
}

h2 {
  font-size : var(--h2-font-size);
}

h3 {
  font-size : var(--h3-font-size);
}

h4 {
  font-size : var(--h4-font-size);
}

.main h1 {
  margin-bottom : var(--block-spacing);
  padding-top   : var(--block-spacing);
}

.content-wrapper {
  width        : calc(100% - 2rem);
  max-width    : var(--max-width);
  margin-left  : 1rem;
  margin-right : 1rem;
}

@media screen and (min-width: 1024px) {

  .content-wrapper {
    width        : 100%;
    margin-left  : auto;
    margin-right : auto;
  }

}


.section-container {
  margin-bottom : var(--section-spacing);
}

.section-container h1,
.section-container h2 {
  text-align : center;
}

.image-container {
  width          : 100%;
  height         : 0;
  overflow       : hidden;
  position       : relative;
  padding-bottom : 65%;
}

.image-container img {
  position   : absolute;
  top        : 0;
  left       : 0;
  width      : 100%;
  height     : 100%;
  object-fit : cover;
}

/* ----- BOTONES ----- */

.button {
  display         : inline-block;
  padding         : 0.75rem 1.25rem;
  border-radius   : 1.5rem;
  text-decoration : none;
  font-weight     : 600;
  color           : var(--white-color);
  background      : var(--color);
  border          : 1px solid var(--color);
}

.button:hover {
  background : var(--color-alt);
}

.button.white {
  --color     : var(--white-color);
  --color-alt : var(--body-bg-color);
  color       : var(--primary-color);
}

.button.ghost {
  color      : var(--color);
  background : transparent;
}

.button.ghost:hover {
  color      : var(--white-color);
  background : var(--color);
}

.button.ghost.white:hover {
  color      : var(--primary-color);
  background : var(--color);
}

/*  ----- MENÚ PRINCIPAL -----  */
.main-header {
  display    : flex;
  position   : fixed;
  top        : 0;
  height     : var(--header-height);
  width      : 100%;
  background : var(--white-color);
  box-shadow : 0 3px 5px rgba(112, 126, 132, 0.1);
  z-index    : var(--z-fixed);
}

.main-header .content-wrapper {
  display     : flex;
  align-items : center;
}

.main-header .jam {
  margin-left : auto;
  color     : var(--title-color);
  font-size : 2.5rem;
  cursor    : pointer;
}

@media screen and (min-width: 1024px) {

    .main-header .jam {
      display : none;
    }
  
}

@media screen and (min-width: 1024px) {

    .main-header .jam-close {
      display : none;
    }
  
}

.main-header img {
    height : 1.5rem;
}

@media screen and (min-width: 1024px) {

    .main-header img {
      height : 2rem;
    }
  
}


.main-menu {
    display         : flex;
    justify-content : flex-end;
    align-items     : center;
    padding-right   : 5%; 
}

@media screen and (min-width: 1024px) {

    .main-menu {
      margin-left : auto;
    }
  
}

@media screen and (max-width: 1024px) {

    .main-menu {
      position        : fixed;
      top             : 0;
      right           : 0;
      // width           : 50%;
      height          : 100vh;
      background      : var(--white-color);
      z-index         : var(--z-off-canvas);
      // transform       : scale(0);
      transition      : transform 0.25s;      
    }
  
}

.main-menu.active {
    transform : initial;
}


.main-menu ul {
    list-style   : none;
    padding-left : 0;
    margin       : 0;
}

@media screen and (min-width: 1024px) {

    .main-menu ul {
      display : flex;
    }
  
}
  

.main-menu .jam-close {
    position : absolute;
    right    : 1rem;
    top      : 1rem;
    color    : #282D31;
}


.main-menu a {
    display         : block;
    padding         : 0.75rem 1rem;
    color           : #282D31;
    text-decoration : none;
    font-weight     : 600;
}

.main-menu a:hover {
    color : var(--primary-color);
}

@media screen and (min-width: 1024px) {

  .main-menu ul li:last-of-type a {
    padding-right : 0;
  }

}


@media screen and (max-width: 1024px) {

    .main-menu a {
      text-align : center;
      font-size  : var(--big-font-size);
    }
  
}



@media screen and (min-width: 1024px) {

  .main-header img {
    height : 2rem;
  }

}


/* ----- BANNER PRINCIPAL ----- */

.main-banner {
  display    : flex;
  background : var(--color);
}

@media screen and (min-width: 1024px) {

  .main-banner {
    height: 100%;
  }

}

@media screen and (max-width: 1024px) {

  .main-banner {
    text-align : center;
    padding    : 2rem 0;
  }

}

.main-banner .content {
  display : grid;
  gap     : 2rem;
}

@media screen and (min-width: 1024px) {

  .main-banner .content {
    grid-template-columns : 1fr 1fr;
  }

}

@media screen and (min-width: 1024px) {

  .main-banner .container {
    display         : flex;
    flex-direction  : column;
    justify-content : center;
  }

  .main-banner .container:last-of-type {
    align-items : center;
  }

}

.main-banner .title {
  color         : var(--white-color);
  margin-bottom : 1rem;
}

@media screen and (min-width: 1024px) {

  .main-banner .title {
    text-align : left;
  }

}

.main-banner .subtitle {
  margin-bottom : 1.5rem;
  font-size     : var(--big-font-size);
  color         : var(--white-color);
  opacity       : 0.7;
}

.main-banner .image {
  width : 80%;
  border-radius: 50%;
}

/* ----- SERVICIOS ----- */

.services-section .content-wrapper {
  display         : grid;
  gap             : 2rem;
  justify-content : center;
}


@media screen and (min-width: 1024px) {

  .services-section .content-wrapper {
    grid-template-columns : repeat(3, 1fr);
  }

}


.service-item {
  display        : flex;
  flex-direction : column;
  align-items    : center;
  text-align     : center;
  max-width      : 300px;
  margin         : 0 auto;
}

.service-item .jam {
  margin-bottom : 1rem;
  color         : var(--primary-color);
  font-size     : 4rem;
}


.service-item h3 {
  margin-bottom : 0.5rem;
}

.service-item p {
  margin-bottom : 0;
}

.services-section h2 {
  margin-bottom : var(--block-spacing);
}



/* ----- ABOUT ME ----- */

.about-section {
  position   : relative;
  padding    : var(--block-spacing) 0;
  background : var(--primary-color);
  color      : var(--white-color);
  text-align : center;
}

.about-estudios {
  text-align  : center;
}

@media screen and (min-width: 1024px) {

  .about-estudios {
    text-align  : right;
  }

}

.about-section .content-wrapper {
  position : relative;
  z-index  : var(--z-front);
}

.about-section .title {
  color : var(--white-color);
}

.about-section p {
  opacity : 0.85;
}

.about-section p:first-of-type {
  margin-bottom : 0.75rem;
}

.about-section p:nth-of-type(2) {
  margin-bottom: 2rem;
}

.about-section .button {
  margin-bottom : 0.5rem;
}

.about-section .btn-subtitle {
  font-size : var(--smaller-font-size);
  opacity   : 0.7;
}

.about-section .background {
  position   : absolute;
  left       : 0;
  top        : 0;
  width      : 100%;
  height     : 100%;
  object-fit : cover;
  z-index    : var(--z-normal);
  opacity    : 0.1;
}



/* ----- ÚLTIMOS PROYECTOS ----- */
.last-projects {
  display        : flex;
  flex-direction : column;
  align-items    : center;
}

.last-projects h2 {
  margin-bottom : var(--block-spacing);
}

.last-projects .content-wrapper {
  display       : grid;
  gap           : 2rem;
  margin-bottom : var(--block-spacing);
}

@media screen and (min-width: 1024px) {

  .last-projects .content-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

}

.project-item {
  text-decoration : none;
  border-radius   : var(--border-radius);
  box-shadow      : var(--box-shadow);
  background      : var(--primary-color);
  overflow        : hidden;
}

.project-item:hover img {
  opacity     : 0.1;
  will-change : opacity;
}

.project-item:hover article > div:first-of-type {
  will-change : opacity;
  opacity     : 1;
}

.project-item article {
  position : relative;
}

.project-item img {
  transition: opacity 0.25s;
}

.project-item article > div:first-of-type {
  display         : flex
  ;
  flex-direction  : column;
  justify-content : center;
  align-items     : center;
  position        : absolute;
  top             : 0;
  left            : 0;
  width           : 100%;
  height          : 100%;
  opacity         : 0;
  color           : var(--white-color);
  transition      : opacity 0.25s;
  z-index         : var(--z-front);
}

.project-item article > div:first-of-type h4 {
  margin-bottom : 0.25rem;
  color         : var(--white-color);
}

.project-item article > div:first-of-type p {
  margin-bottom : 0;
  opacity       : 0.8;
  font-size     : var(--small-font-size);
}

/*  ----- TRABAJA CON NOSOTROS -----  */

.work-with-us {
  display         : flex;
  flex-direction  : column;
  min-height      : 50vh;  
  padding-bottom  : 2rem;
  text-align      : center;
  align-items     : center;
  justify-content : flex-end;

}

.work-with-us .title {
  margin-bottom : 1rem;
}

@media screen and (min-width: 1024px) {

  
.work-with-us {
  display: flex;
  flex-direction: column;
  min-height: 30vh;  
  position   : relative;
  padding-bottom    : 2rem;
  text-align : center;
  align-items: center;
  justify-content: flex-end;

}


  .work-with-us .title {
    margin-bottom : 1.5rem;
  }

}

.work-with-us .subtitle,
.work-with-us .button {
  margin-bottom : 1.5rem;
}

@media screen and (min-width: 1024px) {

  .work-with-us .subtitle,
  .work-with-us .button {
    margin-bottom : 2rem;
  }

}

/*  ----- REDES SOCIALES -----  */

.social-networks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-networks a {
  padding         : 0.25rem;
  color           : var(--text-color);
  font-size       : 2rem;
  text-decoration : none;
}

.social-networks a:hover {
  color : var(--primary-color);
}

.social-networks a:not(:last-of-type) {
  margin-right : 1.5rem;
}

/*  ----- FOOTER -----  */

.main-footer {
  display         : flex;
  justify-content : center;
  align-items     : center;
  margin-top      : auto;
  width           : 100%;
  height          : var(--header-height);
  font-size       : var(--small-font-size);
  position        : absolute;
  bottom          : 0;
}

.main-footer p {
  margin-bottom : 0;
}

/*  ----- PROYECTOS -----  */
.projects-section .content-wrapper {
  display : grid;
  gap     : 2rem;
}

@media screen and (min-width: 1024px) {

  .projects-section .content-wrapper {
    grid-template-columns : repeat(3, 1fr);
  }

}

.card-project {
  border-radius : var(--border-radius);
  overflow      : hidden;
  box-shadow    : var(--box-shadow);
}

.card-project a {
  display         : inline-flex;
  align-items     : center;
  text-decoration : none;
  font-weight     : 600;
}

.card-project h3 {
  margin-bottom : 0.5rem;
}

.card-project p {
  margin-bottom : 1rem;
}

.card-project a span:first-of-type {
  margin-right : 0.25rem;
}

.card-project .jam {
  font-size : var(--big-font-size);
}

.card-project > div:nth-of-type(2) {
  padding    : 1rem;
  background : var(--white-color);
}

/*  ----- QUIÉNES SOMOS -----  */

.about-us .content-wrapper {
  display     : grid;
  gap         : var(--block-spacing);
  --max-width : 800px;
}

.about-item {
  display    : grid;
  gap        : 1.5rem;
  text-align : center;
}

.about-item > div:first-of-type {
  order : 2;
}

@media screen and (min-width: 1024px) {

  .about-item:nth-child(odd) > div:nth-of-type(2) {
    order : 2;
  }

}

@media screen and (min-width: 1024px) {

  .about-item {
    grid-template-columns : 1fr 1fr;
    gap                   : 2rem;
    text-align            : left;
  }

}

.about-item h2 {
  margin-bottom : 0.5rem;
}

.about-item p {
  margin-bottom : 0;
}

@media screen and (min-width: 1024px) {

  .about-item h2 {
    text-align : left;
  }
  .about-estudios h2 {
    text-align: right;
  }

}

.about-item img {
  border-radius : var(--border-radius);
}

/*  ----- CONTACTO -----  */

.contact-section .content-wrapper {
  display    : grid;
  gap        : var(--block-spacing);
  text-align : center;
}

@media screen and (min-width: 1024px) {

  .contact-section .content-wrapper {
    --max-width           : 800px;
    grid-template-columns : 1fr 1fr;
    text-align            : left;
  }

}

.contact-section h4 {
  margin-bottom : 0.5rem;
}

.contact-section address {
  display    : grid;
  gap        : 1rem;
  font-style : normal;
}

@media screen and (min-width: 1024px) {

  .contact-section address {
    order : -1;
    gap   : 2rem;
  }

}

.contact-item .jam {
  font-size : 1rem;
}

.map-ubication {
  width         : 100%;
  height        : 200px;
  overflow      : hidden;
  border-radius : var(--border-radius);
}

.menu-icon :hover {
  cursor: pointer;
}
